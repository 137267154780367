import { checkStatus } from '../../utilities/fetch';
import { eventsPath } from '../../utilities/api_relative_paths';
import { EventsResponse, FilteringOptions } from '../types/events.types';
import { createQueryString } from '../../utilities/utilities';

const getEvents = (filteringOptions: FilteringOptions): Promise<EventsResponse> => {
  return fetch(eventsPath + createQueryString(filteringOptions))
    .then(checkStatus)
    .then((response) => response.json())
    .catch((error) => Promise.reject(error));
};

export { getEvents };
