import React from 'react';
import { Event } from '../../types/events.types';
import { format } from 'date-fns';
import { EVENTS_PATH } from '../../../utilities/devex_paths';
const CLOUDINARY_BASE_PATH = 'https://res.cloudinary.com/devex/image';
const FALLBACK_IMAGE_FILENAME = 'devex-generic-cccdb08fd0bb6bcf8a8a2d3b6c21a76b.png';
const rowMaxHeight = 184;

const EVENT_PREVIEW_IMAGE_PATH_FOR_DESKTOP = `
  ${CLOUDINARY_BASE_PATH}/fetch/c_fill,h_${rowMaxHeight},w_264/
`;

const FALLBACK_EVENT_IMAGE_URL_FOR_DESKTOP = `
  ${CLOUDINARY_BASE_PATH}/asset/c_fill,h_${rowMaxHeight},w_264/${FALLBACK_IMAGE_FILENAME}
`;

const EVENT_PREVIEW_IMAGE_PATH_FOR_MOBILE = `
  ${CLOUDINARY_BASE_PATH}/fetch/c_fill,h_${rowMaxHeight},w_520/
`;

const FALLBACK_EVENT_SRC_URL_FOR_MOBILE = `
  ${CLOUDINARY_BASE_PATH}/asset/c_fill,h_${rowMaxHeight},w_520/${FALLBACK_IMAGE_FILENAME}
`;

interface Props {
  event: Event;
}

const PastEvent: React.FC<Props> = ({ event }) => {
  const EVENT_PATH = `${EVENTS_PATH}/${event.slug_and_id}`;

  const eventImageUrlForMobile = event?.preview_image
    ? EVENT_PREVIEW_IMAGE_PATH_FOR_MOBILE + event?.preview_image
    : FALLBACK_EVENT_SRC_URL_FOR_MOBILE;

  const renderImageForMobile = (
    <div className='d-block d-md-none'>
      <img src={eventImageUrlForMobile} alt={event.title} className='card-img-top' />
    </div>
  );

  const eventImageUrlForDesktop = event?.preview_image
    ? EVENT_PREVIEW_IMAGE_PATH_FOR_DESKTOP + event?.preview_image
    : FALLBACK_EVENT_IMAGE_URL_FOR_DESKTOP;

  const renderImageForDesktop = (
    <div className='d-none d-md-block'>
      <img src={eventImageUrlForDesktop} alt={event.title} className='img-fluid' />
    </div>
  );

  return (
    <div className='col'>
      <div className='card border-0 h-100'>
        {renderImageForMobile}
        {renderImageForDesktop}
        <div className='card-body ps-1'>
          <h3 className='card-title fw-bold fs-5 devex-font-family-sans-serif'>{event.title}</h3>
          <p className='card-text'>
            <i className='fal icon-calendar-alt pe-15' />
            {format(new Date(event.starts_at), 'LLLL, yyyy')}
          </p>
        </div>
        <div className='card-footer ps-0 border-0 bg-transparent'>
          <a href={EVENT_PATH} className='btn btn-outline-secondary'>
            View
          </a>
        </div>
      </div>
    </div>
  );
};

export default PastEvent;
