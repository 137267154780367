import React, { useState, useEffect } from 'react';
import { getEvents } from '../../services/events.service';
import { Event } from '../../types/events.types';
import styles from './upcoming_events_list.module.scss';
import NoData from '../../../components/react/no_data/no_data';
import AlertPopup from '../../../components/react/alert_popup/alert_popup';
import { GENERIC_ERROR_MESSAGE } from '../../../components/react/alert_popup/constants';
import { AlertDataType } from '../../../components/react/alert_popup/types';
import UpcomingEvent from '../upcoming_event/upcoming_event';
import ErrorBoundary from '../../../components/react/error_boundary/error_boundary';

interface Props {
  showAllEventsLogic: boolean;
  numberOfEventsOnMount: number;
}

const UpcomingEventsList: React.FC<Props> = ({ showAllEventsLogic, numberOfEventsOnMount }) => {
  const [showAllEventsSection, setShowAllEventsSection] = useState<boolean>(showAllEventsLogic);
  const [alertData, setAlertData] = useState<AlertDataType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);

  useEffect(() => {
    getEvents({ status: 'upcoming', page: { size: numberOfEventsOnMount } })
      .then((data) => {
        setUpcomingEvents(data.data);
        setShowAllEventsSection(showAllEventsLogic && data.total > numberOfEventsOnMount);
      })
      .catch(() => {
        setAlertData({ type: 'error', message: GENERIC_ERROR_MESSAGE });
        setShowAllEventsSection(false);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onSeeAllEventsClick = () => {
    getEvents({ status: 'upcoming', page: { size: 100 } })
      .then((data) => {
        setUpcomingEvents(data.data);
        setShowAllEventsSection(false);
      })
      .catch(() => {
        setAlertData({ type: 'error', message: GENERIC_ERROR_MESSAGE });
        setShowAllEventsSection(false);
      })
      .finally(() => setIsLoading(false));
  };

  const renderSingleUpcomingEventLoadingPlaceholder = (
    <div className='row mt-4'>
      <div className='col-12 pb-0 col-md-3 pe-md-0 pb-md-0'>
        <span className={`placeholder w-100 ${styles.eventImgHeight}`} />
      </div>
      <div className='col-12 pt-0 col-md-9 ps-md-0 pt-md-0'>
        <p className='placeholder-glow mb-0'>
          <span className={`placeholder w-100 ${styles.eventImgHeight}`} />
        </p>
      </div>
    </div>
  );

  const renderEventsListLoadingPlaceholder = (
    <span data-testid='events-list-loading'>
      {[...Array(numberOfEventsOnMount)].map((_element, index) => (
        <span key={index}>{renderSingleUpcomingEventLoadingPlaceholder}</span>
      ))}
    </span>
  );

  const renderNoResultsFound = (
    <NoData small className='mt-4' textDescription='No upcoming events' />
  );

  const renderEventsList = (
    <div
      className={`d-flex flex-column ${styles.upcomingEventsContainer}`}
      data-testid='upcoming-events-list'
    >
      {upcomingEvents.map((event: Event) => (
        <ErrorBoundary key={event.id}>
          <UpcomingEvent event={event} />
        </ErrorBoundary>
      ))}
    </div>
  );

  const renderUpcomingEvents = upcomingEvents.length ? renderEventsList : renderNoResultsFound;

  return (
    <>
      <AlertPopup alertData={alertData} setAlertData={setAlertData} />
      {isLoading ? renderEventsListLoadingPlaceholder : renderUpcomingEvents}
      {showAllEventsSection && (
        <div data-testid='show-all-button-section' className='row text-center mt-48'>
          <div className='col'>
            <button
              className='btn btn-outline-primary'
              disabled={isLoading}
              onClick={onSeeAllEventsClick}
            >
              See all events
              <i className='fal icon-angle-down ms-2' />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default UpcomingEventsList;
