import React, { useState, useEffect } from 'react';
import { getEvents } from '../../services/events.service';
import { Event } from '../../types/events.types';
import styles from './past_events_list.module.scss';
import NoData from '../../../components/react/no_data/no_data';
import AlertPopup from '../../../components/react/alert_popup/alert_popup';
import { GENERIC_ERROR_MESSAGE } from '../../../components/react/alert_popup/constants';
import { AlertDataType } from '../../../components/react/alert_popup/types';
import ErrorBoundary from '../../../components/react/error_boundary/error_boundary';
import PastEvent from '../past_event/past_event';

interface Props {
  showMoreEventsLogic: boolean;
  numberOfEvents?: number;
}

const PastEventsList: React.FC<Props> = ({ showMoreEventsLogic = true, numberOfEvents = 8 }) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [alertData, setAlertData] = useState<AlertDataType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pastEvents, setPastEvents] = useState<Event[]>([]);
  const [showLoadMoreSection, setShowLoadMoreSection] = useState<boolean>(showMoreEventsLogic);

  const onLoadMorePastEventsClick = () => {
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    getEvents({ status: 'past', page: { size: numberOfEvents, number: pageNumber } })
      .then((data) => {
        setShowLoadMoreSection(showMoreEventsLogic && data.total > pageNumber * numberOfEvents);
        setPastEvents([...pastEvents, ...data.data]);
      })
      .catch(() => {
        setAlertData({ type: 'error', message: GENERIC_ERROR_MESSAGE });
        setShowLoadMoreSection(false);
      })
      .finally(() => setIsLoading(false));
  }, [pageNumber]);

  const renderSingleEventLoadingPlaceholder = (
    <div className='col-lg-3 col-md-6 col-12'>
      <div className='card border-0' aria-hidden='true'>
        <div className='card-body'>
          <span className={`placeholder w-100 ${styles.pastEventImage}`} />
          <h4 className='card-title placeholder-glow'>
            <span className='placeholder col-12' />
            <span className='placeholder col-12' />
            <span className='placeholder col-6' />
          </h4>
          <button className='mt-4 btn btn-outline-secondary' disabled={true}>
            View
          </button>
        </div>
      </div>
    </div>
  );

  const renderEventsListLoadingPlaceholder = (
    <div className='row mt-4'>
      {renderSingleEventLoadingPlaceholder}
      {renderSingleEventLoadingPlaceholder}
      {renderSingleEventLoadingPlaceholder}
      {renderSingleEventLoadingPlaceholder}
    </div>
  );

  const renderEventsList = (
    <div
      data-testid='past-events-list'
      className='row mb-45 row-cols-1 row-cols-md-2 row-cols-lg-4 g-4'
    >
      {pastEvents.map((event: Event) => (
        <ErrorBoundary key={event.id}>
          <PastEvent event={event} />
        </ErrorBoundary>
      ))}
    </div>
  );

  const renderNoResultsFound = <NoData small textDescription='No past events' />;

  const renderPastEvents = pastEvents.length ? renderEventsList : renderNoResultsFound;

  return (
    <>
      <AlertPopup alertData={alertData} setAlertData={setAlertData} />
      {isLoading ? renderEventsListLoadingPlaceholder : renderPastEvents}
      {showLoadMoreSection && (
        <div data-testid='load-more-button-section' className='row text-center mt-2 mt-lg-4'>
          <div className='col'>
            <button
              className='btn btn-outline-primary'
              disabled={isLoading}
              onClick={onLoadMorePastEventsClick}
            >
              Load more
              <i className='fal icon-angle-down ms-2' />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PastEventsList;
